var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        [
          _c(
            "vs-tabs",
            [
              _c("vs-tab", { attrs: { label: _vm.$t("configuracao") } }, [
                _c("div", { staticClass: "mt-4" }, [
                  _c(
                    "div",
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("Questionnaire")))]),
                      _c("vs-divider", { staticClass: "mt-0" }),
                      _c("select-suggestion", {
                        ref: "select_questionnaire",
                        class: _vm.invalidQuestionnaire ? "required" : "",
                        attrs: {
                          column: "name,description",
                          model: "ContentQuestionnaire",
                          appendClearOption: true,
                          placeholderText: "Digite o nome do questionário",
                          isDisabled: _vm.isEdit,
                        },
                        model: {
                          value: _vm.questionnaire,
                          callback: function ($$v) {
                            _vm.questionnaire = $$v
                          },
                          expression: "questionnaire",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "grid w-full grid-cols-3 gap-2 align-baseline",
                      },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["##########"],
                              expression: "['##########']",
                            },
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: _vm.$t(
                                  "quantidade-total-de-participantes-que-podem"
                                ),
                                delay: { show: 800, hide: 500 },
                              },
                              expression:
                                "{\n                content: $t('quantidade-total-de-participantes-que-podem'),\n                delay: {show: 800, hide: 500},\n              }",
                            },
                          ],
                          class:
                            "w-full " +
                            (_vm.model.total_user_limit ? "" : "required"),
                          attrs: {
                            label: _vm.$t("limite-de-participantes"),
                            type: "number",
                          },
                          model: {
                            value: _vm.model.total_user_limit,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "total_user_limit", $$v)
                            },
                            expression: "model.total_user_limit",
                          },
                        }),
                        _c("vs-input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["##########"],
                              expression: "['##########']",
                            },
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content:
                                  "Limite de participantes por cada sala.",
                                delay: { show: 800, hide: 500 },
                              },
                              expression:
                                "{\n                content: 'Limite de participantes por cada sala.',\n                delay: {show: 800, hide: 500}\n              }",
                            },
                          ],
                          class:
                            "w-full " +
                            (_vm.model.classroom_capacity ? "" : "required"),
                          attrs: {
                            label: _vm.$t("limite-por-sala"),
                            type: "number",
                          },
                          model: {
                            value: _vm.model.classroom_capacity,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "classroom_capacity", $$v)
                            },
                            expression: "model.classroom_capacity",
                          },
                        }),
                        _c(
                          "fieldset",
                          { class: _vm.recordFieldsContainerStyle() },
                          [
                            _c("legend", { staticClass: "px-2 m-0" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("stream-de-video")) + " "
                              ),
                            ]),
                            _c(
                              "vs-checkbox",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "proctoring.stream_type.live",
                                    expression: "'proctoring.stream_type.live'",
                                  },
                                ],
                                model: {
                                  value: _vm.is_livestreamed,
                                  callback: function ($$v) {
                                    _vm.is_livestreamed = $$v
                                  },
                                  expression: "is_livestreamed",
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("ao-vivo-0")) + " ")]
                            ),
                            _c(
                              "vs-checkbox",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "proctoring.stream_type.record",
                                    expression:
                                      "'proctoring.stream_type.record'",
                                  },
                                ],
                                model: {
                                  value: _vm.is_recorded,
                                  callback: function ($$v) {
                                    _vm.is_recorded = $$v
                                  },
                                  expression: "is_recorded",
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("gravado")) + " ")]
                            ),
                          ],
                          1
                        ),
                        _vm.is_livestreamed
                          ? _c(
                              "fieldset",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "proctoring.stream_type.live_chat",
                                    expression:
                                      "'proctoring.stream_type.live_chat'",
                                  },
                                ],
                                class: _vm.recordFieldsContainerStyle(),
                              },
                              [
                                _c("legend", { staticClass: "px-2 m-0" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("tipo-stream")) + " "
                                  ),
                                ]),
                                _c(
                                  "vs-checkbox",
                                  {
                                    model: {
                                      value: _vm.is_livechat,
                                      callback: function ($$v) {
                                        _vm.is_livechat = $$v
                                      },
                                      expression: "is_livechat",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("chamada-por-video")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass:
                              "gap-x-2 row-start-2 flex items-stretch",
                          },
                          [
                            _c("div", { staticClass: "pt-1 pb-2" }, [
                              _c("label", [_vm._v(_vm._s(_vm.$t("situacao")))]),
                              _c(
                                "div",
                                { staticClass: "flex flex-row gap-x-2 mt-1" },
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("inativa"))),
                                  ]),
                                  _c("vs-switch", {
                                    staticClass: "m-0",
                                    model: {
                                      value: _vm.status,
                                      callback: function ($$v) {
                                        _vm.status = $$v
                                      },
                                      expression: "status",
                                    },
                                  }),
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("ativa"))),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-full flex gap-2 justify-end pt-4" },
                    [
                      _c(
                        "vs-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: "proctoring_config.create",
                              expression: "'proctoring_config.create'",
                            },
                          ],
                          staticClass: "float-right mr-2",
                          attrs: { disabled: _vm.invalidForm },
                          on: { click: _vm.store },
                        },
                        [_vm._v(_vm._s(_vm.$t("action.save")))]
                      ),
                      !_vm.isEmpty(_vm.id)
                        ? _c(
                            "vs-button",
                            {
                              staticClass: "float-right mr-2",
                              attrs: {
                                "icon-pack": "feather",
                                icon: "icon-video",
                                color: "warning",
                                type: "border",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push(
                                    "/proctoring_monitor/" +
                                      _vm.questionnaire.id
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("monitorar")) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "vs-button",
                        {
                          staticClass: "float-right",
                          attrs: { type: "border" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push("/proctoring_config")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("action.back")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm.supervisorTabDisabled
                ? _c(
                    "vs-tab",
                    { attrs: { label: _vm.$t("proctoring-title") } },
                    [
                      _c("div", { staticClass: "mt-5" }, [
                        _c(
                          "div",
                          [
                            _c("select-suggestion", {
                              ref: "selectUser",
                              attrs: {
                                column: "name,email",
                                model: "InstitutionUsers",
                                label: _vm.$t("usuario"),
                                appendClearOption: false,
                                itemTextFormat: function (model) {
                                  return (
                                    model.name +
                                    " <br/><div class='text-sm'>" +
                                    model.email +
                                    "<div>"
                                  )
                                },
                                placeholderText:
                                  "Digite o nome ou e-mail do usuário",
                              },
                              on: { "selected-model": _vm.addSupervisor },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mt-8" },
                          [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("supervisores"))),
                            ]),
                            _c("vs-divider", { staticClass: "mt-0" }),
                            _c(
                              "vs-table",
                              {
                                attrs: { data: _vm.supervisors },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var data = ref.data
                                        return _vm._l(data, function (tr, i) {
                                          return _c(
                                            "vs-tr",
                                            { key: i },
                                            [
                                              _c("vs-td", [
                                                _vm._v(_vm._s("" + tr.name)),
                                              ]),
                                              _c("vs-td", [
                                                _vm._v(_vm._s(tr.email + " ")),
                                              ]),
                                              _c("vs-td", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "w-full flex flex-row items-center justify-start",
                                                  },
                                                  [
                                                    _vm.loggedUserIsSupervisorAdmin &&
                                                    _vm.supervisors
                                                      ? _c("vs-checkbox", {
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.setSupervisorAdmin(
                                                                tr.id,
                                                                _vm.supervisors[
                                                                  i
                                                                ].is_admin
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.supervisors[i]
                                                                .is_admin,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.supervisors[
                                                                  i
                                                                ],
                                                                "is_admin",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "supervisors[i].is_admin",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _c(
                                                      "label",
                                                      {
                                                        key: _vm.isAdminLabelKey,
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            "" +
                                                              (tr.is_admin
                                                                ? _vm.$t("sim")
                                                                : _vm.$t("nao"))
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _c(
                                                "vs-td",
                                                [
                                                  _c("feather-icon", {
                                                    attrs: {
                                                      icon: "Trash2Icon",
                                                      svgClasses:
                                                        "h-5 w-5 mb-1 mr-3 stroke-current text-danger cursor-pointer",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.confirmRemoveSupervisor(
                                                          tr,
                                                          i
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        })
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4172843373
                                ),
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "thead" },
                                  [
                                    _c("vs-th", [
                                      _vm._v(_vm._s(_vm.$t("name"))),
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(_vm._s(_vm.$t("email"))),
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(_vm._s(_vm.$t("administrador"))),
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(_vm._s(_vm.$t("acao"))),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
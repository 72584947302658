<template>
  <div>
    <vx-card>
      <vs-tabs>
        <!-- TAB DE CONFIGURAÇÃO -->
        <vs-tab :label="$t('configuracao')">
          <div class="mt-4">
            <div>
              <label>{{ $t('Questionnaire') }}</label>
              <vs-divider class="mt-0"/>
              <select-suggestion
                :class="invalidQuestionnaire ? 'required' : ''"
                column="name,description"
                model="ContentQuestionnaire"
                v-model="questionnaire"
                :appendClearOption="true"
                ref="select_questionnaire"
                placeholderText="Digite o nome do questionário"
                :isDisabled="isEdit"
              />
            </div>

            <div>
              <div class="grid w-full grid-cols-3 gap-2 align-baseline">
                <vs-input
                :class="`w-full ${model.total_user_limit ? '' : 'required'}`"
                :label="$t('limite-de-participantes')"
                v-model="model.total_user_limit"
                type="number"
                v-mask="['##########']"
                v-tooltip="{
                  content: $t('quantidade-total-de-participantes-que-podem'),
                  delay: {show: 800, hide: 500},
                }"/>

                <vs-input
                :class="`w-full ${model.classroom_capacity ? '' : 'required'}`"
                :label="$t('limite-por-sala')"
                v-model="model.classroom_capacity"
                type="number"
                v-mask="['##########']"
                v-tooltip="{
                  content: 'Limite de participantes por cada sala.',
                  delay: {show: 800, hide: 500}
                }"/>

                <fieldset :class="recordFieldsContainerStyle()">
                  <legend class="px-2 m-0">
                    {{ $t('stream-de-video') }}
                  </legend>
                  <vs-checkbox v-model="is_livestreamed" v-permission="'proctoring.stream_type.live'">
                    {{ $t('ao-vivo-0') }}
                  </vs-checkbox>
                  <vs-checkbox v-model="is_recorded" v-permission="'proctoring.stream_type.record'">
                    {{ $t('gravado') }}
                  </vs-checkbox>
                </fieldset>

                <fieldset :class="recordFieldsContainerStyle()" v-if="is_livestreamed" v-permission="'proctoring.stream_type.live_chat'">
                  <legend class="px-2 m-0">
                    {{ $t('tipo-stream') }}
                  </legend>
                  <vs-checkbox v-model="is_livechat">
                    {{ $t('chamada-por-video') }}
                  </vs-checkbox>
                </fieldset>

                <div class="gap-x-2 row-start-2 flex items-stretch">
                  <div class="pt-1 pb-2">
                    <label>{{ $t('situacao') }}</label>
                    <div class="flex flex-row gap-x-2 mt-1">
                      <label>{{ $t('inativa') }}</label>
                      <vs-switch class="m-0" v-model="status"/>
                      <label>{{ $t('ativa') }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full flex gap-2 justify-end pt-4">
                <vs-button
                  v-permission="'proctoring_config.create'"
                  class="float-right mr-2"
                  @click="store"
                  :disabled="invalidForm"
                  >{{ $t('action.save') }}</vs-button>
                <vs-button
                  v-if="!isEmpty(id)"
                  icon-pack="feather"
                  icon="icon-video"
                  color="warning"
                  type="border"
                  class="float-right mr-2"
                  @click="$router.push(`/proctoring_monitor/${questionnaire.id}`)">
                    {{ $t('monitorar') }}
                  </vs-button>
                  <vs-button class="float-right" type="border" @click="$router.push('/proctoring_config')">
                  {{ $t('action.back') }}
                </vs-button>
            </div>
          </div>
        </vs-tab>

        <!-- TAB DE SUPERVISÃO -->
        <vs-tab :label="$t('proctoring-title')" v-if="supervisorTabDisabled">
          <div class="mt-5">
            <div>
              <select-suggestion
                ref="selectUser"
                column="name,email"
                model="InstitutionUsers"
                @selected-model="addSupervisor"
                :label="$t('usuario')"
                :appendClearOption="false"
                :itemTextFormat="
                  (model) => `${model.name} <br/><div class=\'text-sm\'>${model.email}<div>`
                "
                placeholderText="Digite o nome ou e-mail do usuário"
              />
              <!-- <tag-suggestion
                column="name,email"
                model="ProctorUser"
                v-model="data.supervisors"
                label="Usuários"
                :modelToTagMapping="
                  (model) => ({
                    text: model.name,
                    data: model
                  })
                "
                placeholderText="Digite o nome ou e-mail do usuário"
              /> -->
            </div>
            <div class="mt-8">
              <label>{{ $t('supervisores') }}</label>
              <vs-divider class="mt-0"/>
              <!-- Tabela de Supervisores -->
              <vs-table :data="supervisors">
                <template slot="thead">
                  <vs-th>{{ $t('name') }}</vs-th>
                  <vs-th>{{ $t('email') }}</vs-th>
                  <vs-th>{{ $t('administrador') }}</vs-th>
                  <vs-th>{{ $t('acao') }}</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="i" v-for="(tr, i) in data">
                    <vs-td>{{ `${tr.name}` }}</vs-td>
                    <vs-td>{{ `${tr.email} `}}</vs-td>
                    <vs-td>
                      <div class="w-full flex flex-row items-center justify-start">
                        <vs-checkbox
                          @input="setSupervisorAdmin(tr.id, supervisors[i].is_admin)"
                          v-if="loggedUserIsSupervisorAdmin && supervisors"
                          v-model="supervisors[i].is_admin"
                        />
                        <label :key="isAdminLabelKey">{{ `${tr.is_admin ? $t('sim') : $t('nao')}` }}</label>
                      </div>
                    </vs-td>
                    <vs-td>
                      <feather-icon
                        icon="Trash2Icon"
                        svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger cursor-pointer"
                        @click="confirmRemoveSupervisor(tr, i)"
                      />
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import TagSuggestion from '@/components/TagSuggestion.vue'
import ProctoringService from '@/services/api/ProctoringService'
import { PROCTORING_STREAM_TYPE } from '@/util/Enums'
import { faGlasses } from '@fortawesome/free-solid-svg-icons'

export default {
  components: { SelectSuggestion, TagSuggestion },

  props: {
    id: {
      type: String,
      default: null
    }
  },

  data: () => ({
    supervisors: [],
    model: {
      total_user_limit: null,
      classroom_capacity: null,
    },
    is_livestreamed: false,
    is_recorded: false,
    is_videochat: false,
    questionnaire: null,
    status: true,
    isAdminLabelKey: 0 // We change this to re-render the isAdmin label.
  }),

  computed: {
    questionnaireSelectComponent() {
      return this.$refs['select_questionnaire']
    },
    supervisorTabDisabled() {
      return this.isEdit
    },
    loggedUserIsSupervisorAdmin() {
      return true
    },
    // Validation Computeds
    invalidForm() {
      return this.invalidRecordMedium ||
        this.invalidQuestionnaire ||
        this.invalidLimits ||
        (this.id && this.noSupervisors)
    },
    invalidRecordMedium() {
      return !(this.is_livestreamed || this.is_recorded)
    },
    invalidQuestionnaire() {
      return !(this.questionnaire && this.questionnaire.id)
    },
    invalidLimits() {
      return !(this.model.total_user_limit || this.model.classroom_capacity)
    },
    noSupervisors() {
      return this.supervisors.length === 0
    },
    isEdit(){
      return !_.isNil(this.id)
    },
  },

  watch: {
    is_livestreamed(value) {
      if (!value) {
        this.is_livechat = false
      }
    }
  },

  mounted() {
    this.service = ProctoringService.build(this.$vs)

    if (this.id) this.fillData()
  },

  methods: {
    setRecordFields(mediumtype) {
      switch (mediumtype) {
      case PROCTORING_STREAM_TYPE.LiveRecord:
        this.is_livestreamed = true
        this.is_recorded = true
        break
      case PROCTORING_STREAM_TYPE.Record:
        this.is_recorded = true
        break
      case PROCTORING_STREAM_TYPE.Live:
        this.is_livestreamed = true
        break
      case PROCTORING_STREAM_TYPE.LiveChat:
        this.is_livestreamed = true
        this.is_livechat = true
        break
      case PROCTORING_STREAM_TYPE.LiveChatRecord:
        this.is_livestreamed = true
        this.is_livechat = true
        this.is_recorded = true
        break
      default:
        break
      }
    },
    getRecordMediumType() {
      if (this.is_livestreamed && !this.is_recorded) {
        if (this.is_livechat) {
          return PROCTORING_STREAM_TYPE.LiveChat
        }
        return PROCTORING_STREAM_TYPE.Live
      } else if (this.is_livestreamed && this.is_recorded) {
        if (this.is_livechat) {
          return PROCTORING_STREAM_TYPE.LiveChatRecord
        }
        return PROCTORING_STREAM_TYPE.LiveRecord
      } else if (this.is_recorded) {
        return PROCTORING_STREAM_TYPE.Record
      }
      return ''
    },
    confirmRemoveSupervisor(data, index){
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-excluir-este-registro'),
        accept: () => {this.removeSupervisor(data, index)}
      })
    },
    removeSupervisor(data, index) {
      this.$vs.loading()

      const ids = [data.id];
      ids.push()

      this.service.removeSupervisor(this.id, ids).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.supervisors.splice(index, 1)
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs,this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(() => { this.$vs.loading.close() })
    },
    getAllSupervisor() {
      this.$vs.loading()

      this.service.getAllSupervisor(this.id).then(
        data => {


        },
        error => {
          this.showErrors(this.$validator, error)
        }
      ).finally(() => { this.$vs.loading.close() })
    },
    addSupervisor(data) {
      this.$vs.loading()

      const userIds = [data.id];
      userIds.push()

      this.service.addSupervisor(this.id, userIds).then(
        data => {
          this.clearSupervisorForm()
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.supervisors = data
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs,this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(() => { this.$vs.loading.close() })
    },
    setSupervisorAdmin(supervisorId, isAdmin) {
      this.isAdminLabelKey++
      this.$vs.loading()

      this.service.setSupervisorAdmin(this.id, supervisorId, isAdmin).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))

        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs,this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(() => { this.$vs.loading.close() })
    },
    clearSupervisorForm(){
      this.$refs['selectUser'].reset()
    },
    store() {
      this.$vs.loading()
      const model = {
        id: this.id,
        ...this.model,
        questionnaire_id: (this.questionnaire && this.questionnaire.id) || null,
        record_medium: this.getRecordMediumType(),
        status: this.status ? 'E' : 'D'
      }
      this.service.storeConfig(model).then(
        stored => {
          this.$vs.loading.close()
          const terminology = this.id ? 'alterada' : 'criada'
          this.notifySuccess(this.$vs, this.$t('a-configuracao-de-proctoring-stored-id-foi-terminology-com-sucesso', [stored.id, terminology]))
          if (!this.id && stored.id) {
            this.$router.push(`/proctoring_config/${stored.id}/edit`)
          }
        },
        error => {
          this.$vs.loading.close()
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-essa-operacao'))
          if (this.id) setTimeout(() => {
            this.fillData()
          })
          console.error(error)
        }
      )
    },
    fillData() {
      this.$vs.loading()
      this.service.findConfig(this.id).then(
        config => {
          if (config) {
            this.$vs.loading.close()

            this.supervisors = config.supervisors || []

            if ('questionnaire' in config) {
              this.questionnaire = config.questionnaire
            }
            if ('record_medium' in config && config.record_medium) {
              this.setRecordFields(config.record_medium)
            }
            if ('status' in config && config.status) {
              this.status = config.status === 'E'
            }
            this.model.total_user_limit = config.total_user_limit
            this.model.classroom_capacity = config.classroom_capacity
          }

        },
        error => {
          this.$router.replace('/proctoring_config')
          this.$vs.loading.close()
          console.error(error)
        }
      )
    },

    // Styling/Css-class making methods

    recordFieldsContainerStyle() {
      const base = 'row-start-2 flex gap-2 pl-6 rounded border py-2'
      const borderColor = this.invalidRecordMedium ? 'border-warning' : 'border-faint-grey'
      return `${base} ${borderColor}`
    },
  }

}
</script>

<style lang="scss">
.tooltip {
  z-index: 60000;
}
</style>
